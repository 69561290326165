<template>
  <div class="footerBtnWrap">
    <div class="uploadIcon" @click="dialogVisible = true">
      <i v-if="!imgUrl" class="el-icon-plus avatar-uploader-icon"></i>
      <img v-else :src="imgUrl" />
    </div>
    <!-- 弹出层-裁剪 -->
    <el-dialog
      title="设置Logo"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      class="cropperDialog"
      width="30%"
      @open="cropperOpen"
    >
      <div class="btns">
        <input
          id="uploads"
          ref="filElem"
          type="file"
          accept="image/png, image/jpeg, image/gif, image/jpg"
          class="el-button hide"
          @change="uploadImg($event, 'img', 1)"
        />
        <el-button type="primary" class="clickUpload" @click="clickUpload">上传本地图片</el-button>
        <el-button type="danger" class="danger" @click="del">删除</el-button>
      </div>
      <el-row>
        <el-col :span="24">
          <!-- 裁剪 -->
          <div class="cropper">
            <vueCropper
              ref="cropper"
              style="width:100%;height:380px;"
              :img="attach.customaryUrl"
              :auto-crop="options.autoCrop"
              :fixed-box="options.fixedBox"
              :can-move-box="options.canMoveBox"
              :auto-crop-width="options.autoCropWidth"
              :auto-crop-height="options.autoCropHeight"
              :center-box="options.centerBox"
              :info-true="options.infoTrue"
              :original="options.original"
              :can-move="options.canMove"
              :fixed-number="options.fixedNumber"
              :fixed="options.fixed"
              @realTime="realTime"
            ></vueCropper>
          </div>
        </el-col>
        <!-- <el-col :span="7">
          <div class="show-preview ">
            <div class="picHeader">头像预览</div>
            <div
              :style="{
                borderRadius: '50%',
                width: previews.w + 'px',
                height: previews.h + 'px',
                overflow: 'hidden',
              }"
              class="preview"
            >
              <div :style="previews.div">
                <img :src="previews.url" :style="previews.img" />
              </div>
            </div>
            <div :style="previewStyle1" class="preview">
              <div :style="previews.div">
                <img :src="previews.url" :style="previews.img" />
              </div>
            </div>
            <div :style="previewStyle2" class="preview">
              <div :style="previews.div">
                <img :src="previews.url" :style="previews.img" />
              </div>
            </div>
          </div>
        </el-col> -->
      </el-row>
      <el-row class="footerBtn">
        <el-button type="primary " size="medium" @click="confirm('img')">确认</el-button>
        <el-button size="medium" @click="dialogVisible = false">取消</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { getFileUpload } from '@/api/college'
import to from 'await-to'
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    uploadPictures: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialogVisible: false,
      options: {
        canMove: false, //上传图片是否可以移动
        autoCrop: true, //默认生成截图框
        fixedBox: false, //固定截图框大小
        canMoveBox: true, //截图框不能拖动
        centerBox: true, //截图框被限制在图片里面
        autoCropWidth: 200, //截图框宽度
        autoCropHeight: 200, //截图框高度
        original: true, // 上传图片是否显示原始宽高
        infoTrue: false, // 为展示真实输出图片宽高 false 展示看到的截图框宽高
        fixedNumber: [1, 1], // 截图框的宽高比例 [宽度, 高度]
        fixed: true,
        outputType: 'jpeg',
        url: '',
        mode: '200px 200px', //图片默认渲染方式
      },
      previews: {}, //实时预览图数据
      attach: {
        //后端附件表
        id: '',
        userId: '',
        customaryUrl: '', //原图片路径
        laterUrl: '', //裁剪后图片路径  /static/logo.png
        attachType: 'photo', //附件类型
      },
      imgUrl: '',
      previewStyle1: '',
      previewStyle2: '',
      result: {},
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.imgUrl = val
        this.uploadPictures.clearValidate()
      }
    },
  },
  created() {},
  methods: {
    cropperOpen() {
      if (this.imgUrl !== '') {
        this.attach.customaryUrl = this.imgUrl + '?a=1'
      }
    },
    // 实时预览函数
    realTime(data) {
      var previews = data
      var h = 0.5
      var w = 0.2
      this.previewStyle1 = {
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        margin: '0',
        borderRadius: '50%',
        zoom: h,
      }
      this.previewStyle2 = {
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        margin: '0',
        borderRadius: '50%',
        zoom: w,
      }
      this.previewStyle3 = {
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        margin: '0',
        zoom: 100 / previews.w,
      }
      this.previewStyle4 = {
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        margin: '0',
        zoom: 100 / previews.h,
      }
      this.previews = data
    },
    //点击图片调取input
    clickUpload() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    del() {
      this.attach.customaryUrl = ''
    },
    //选择本地图片
    uploadImg(e, type, num) {
      var file = e.target.files[0]
      console.log(e)
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        this.$message.error('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种')
        return false
      }
      //fileReader 接口，用于异步读取文件数据
      var reader = new FileReader()
      reader.onload = e => {
        let data = '' //生成图片地址
        if (typeof e.target.result === 'object') {
          if (type == 'blob') {
            // 把Array Buffer转化为blob 如果是base64不需要
            data = window.URL.createObjectURL(new Blob([e.target.result]))
          }
        } else {
          data = e.target.result
        }
        if (num === 1) {
          this.attach.customaryUrl = data
        }
      }
      if (type == 'blob') {
        // 转化为blob
        reader.readAsArrayBuffer(file)
      } else {
        // 转化为base64
        reader.readAsDataURL(file)
      }
    },
    translateBase64ImgToFile(base64, filename, contentType) {
      var arr = base64.split(',') //去掉base64格式图片的头部
      var bstr = atob(arr[1]) //atob()方法将数据解码
      var leng = bstr.length
      var u8arr = new Uint8Array(leng)
      while (leng--) {
        u8arr[leng] = bstr.charCodeAt(leng) //返回指定位置的字符的 Unicode 编码
      }
      return new File([u8arr], filename, { type: contentType })
    },
    async requestImg(params) {
      const [res, err] = await to(getFileUpload(params))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.imgUrl = res.data
      this.$emit('input', res.data)
      this.uploadPictures.clearValidate()
    },
    //确认截图,上传
    confirm(type) {
      let formData = new FormData()
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob(data => {
          formData.append('file', data)
          formData.append('fileTypeCode', 'F001')
          this.requestImg(formData)
          this.dialogVisible = false
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          let result = this.translateBase64ImgToFile(data, 'testImg.png', 'image/png')
          formData.append('file', result)
          this.requestImg(formData)
          this.dialogVisible = false
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.uploadIcon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}
/* 弹性布局 水平居中 */
.el-dialog {
  // width: 700px !important;
  height: auto;
}
.picHeader {
  text-align: center;
}
.show-preview {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 120px;
}
.preview {
  border-radius: 50%;
  margin-top: 10px !important;
}
.upload-img {
  width: 100px;
  height: 30px;
  border: 1px solid #f08512;
  margin-bottom: 5px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
.footerBtn {
  display: flex;
  margin-top: 15px;
  .el-button--primary {
    margin-left: 300px;
  }
}
.hide {
  display: none;
}

.cropperDialog {
  ::v-deep .el-dialog__body {
    padding-top: 0 !important;
  }
  .btns {
    margin: 20px 0;
    .clickUpload {
      margin-left: 0;
    }
    .danger {
      background: #f8341e;
    }
  }
}
</style>
